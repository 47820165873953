import "./App.css";
import Home from "./pages/home/Home";
import Project from "./pages/project/Project";
import Nav from "./pages/nav/Nav";
import {
  Box,
  createMuiTheme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useEffect, useRef, useState } from "react";
import MenuModal from "./pages/nav/MenuModal";
import { useSpring, animated, config } from "react-spring";
import About from "./pages/about/About";
// import Process from "./pages/Process";
import Contact from "./pages/contact/Contact";
import { verifyAuth } from "./components/firebaseLogin";
import AdminModal from "./pages/admin/AdminModal";
// const useStyles = makeStyles((theme) => ({
//   height550: {
//     height: 1050,
//   },
//   height: {
//     height: "auto",
//   },
// }));

const App = () => {
  const [currentYPos, setCurrentYPos] = useState(() => 0);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAdminModal, setToggleAdminModal] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  // const refProcess = useRef(null);

  const refAbout = useRef(null);
  const refProject = useRef(null);
  const refHome = useRef(null);
  const refContact = useRef(null);
  const [offsetTop, setOffsetTop] = useState({});

  let bgColor;
  let fontColor;
  useScrollPosition(({ prevPos, currPos }) => {
    setCurrentYPos(currPos.y);
  });

  console.log("offsetTop", offsetTop);

  // show/hide scroll bar on the right if modal is open
  useEffect(() => {
    toggleAdminModal && (document.body.style.overflow = "hidden");
    !toggleAdminModal && (document.body.style.overflow = "unset");
  }, [toggleAdminModal]);

  if (currentYPos > -offsetTop.project + 100) {
    bgColor = "#FFFFE0";
    fontColor = "black";
  }
  // else if (currentYPos >= -offsetTop.process + 100) {
  //   bgColor = "white";
  //   fontColor = "black";
  // }
  else if (currentYPos >= -offsetTop.about + 100) {
    bgColor = "lavender";
    fontColor = "black";
  } else if (currentYPos >= -offsetTop.contact + 100) {
    bgColor = "white";
    fontColor = "black";
  } else {
    bgColor = "#FFFFF0";
    fontColor = "black";
  }

  const setOffsetTopVarHolder = () =>
    setOffsetTop({
      home: refHome.current.offsetTop,
      project: refProject.current.offsetTop,
      // process: refProcess.current.offsetTop,
      about: refAbout.current.offsetTop,
      contact: refContact.current.offsetTop,
    });

  useEffect(() => {
    // this create an object with all the bound top values
    // console.log("refProcess.current.offsetTop", refProcess.current.offsetTop);

    setTimeout(() => {
      setOffsetTopVarHolder();
    }, 1000);
  }, []);

  // if website resizes recalculate the OffSetTop
  useEffect(() => {
    const setOffSetTopVariable = () => {
      console.log("setoffTop resized");
      setOffsetTopVarHolder();
    };

    window.addEventListener("resize", setOffSetTopVariable);
    return () => {
      window.removeEventListener("resize", setOffSetTopVariable);
    };
  }, []);

  useEffect(() => {
    verifyAuth(setLoggedIn);
  }, []);

  const bgColorChange = useSpring({
    config: config.molasses,
    from: { background: bgColor, color: fontColor },
    to: { background: bgColor, color: fontColor },
  });

  const theme = createMuiTheme();

  return (
    <>
      <ThemeProvider theme={theme}>
        {toggleModal && (
          <MenuModal
            setToggleModal={setToggleModal}
            offsetTop={offsetTop}
            toggleModal={toggleModal}
          />
        )}
        {toggleAdminModal && (
          <AdminModal
            setToggleAdminModal={setToggleAdminModal}
            toggleAdminModal={toggleAdminModal}
          />
        )}
        <animated.div style={bgColorChange}>
          <>
            <Box style={{ maxWidth: 900 }} mx='auto'>
              {/* <Box style={{ position: "fixed", top: 100, left: 20 }}>
                <Typography variant='h3'>{currentYPos}</Typography>
              </Box> */}
              <Nav
                setToggleModal={setToggleModal}
                toggleModal={toggleModal}
                offsetTop={offsetTop}
                currentYPos={currentYPos}
                loggedIn={loggedIn}
                setToggleAdminModal={setToggleAdminModal}
              />
              <div ref={refHome}>
                <Box style={{ height: "100vh" }}>
                  <Home />
                </Box>
              </div>
              <div ref={refProject}>
                <Box>
                  <Project />
                </Box>
              </div>
              {/* <div ref={refProcess}>
              <Box className={classes.height550}>
                <Process />
              </Box>
            </div> */}
              <div ref={refAbout}>
                <Box style={{ minheight: 500 }}>
                  <About />
                </Box>
              </div>
              <div ref={refContact}>
                <Box style={{ height: "100vh" }}>
                  <Contact />
                </Box>
              </div>
            </Box>
          </>
        </animated.div>
      </ThemeProvider>
    </>
  );
};

export default App;
