import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

const Contact = () => {
  return (
    <>
      <Box
        textAlign='center'
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'
        flexDirection='column'
      >
        <Grid>
          <Grid item>
            <Box>
              <Typography variant='h2'>Dat Nguyen</Typography>
            </Box>
            <Box mt={3}>
              <Typography variant='h5'>Datguy7@gmail.com</Typography>
            </Box>
            <Box mt={3}>
              <IconButton
                data-testid='linkedIn'
                href='https://www.linkedin.com/in/dat7'
                rel='noopener noreferrer'
                target='_blank'
              >
                <LinkedInIcon style={{ fontSize: "6em", color: "#0A66C2" }} />
              </IconButton>
              <IconButton
                data-testid='github'
                style={{ padding: 26 }}
                href='https://github.com/Zevez7'
                rel='noopener noreferrer'
                target='_blank'
              >
                <GitHubIcon style={{ fontSize: "5em", color: "#2188FF" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
