import { useCallback, useState } from "react";
import { fireImageURL } from "../components/firebaseFx";
import defaultIMG from "../image/kalen-emsley-Bkci_8qcdvQ-unsplash.jpg";

const useNameToURL = () => {
  // use the default in document URL
  const [srcURL, setSrcURL] = useState(defaultIMG);

  // call this function to get the IMG URL from the name
  const fireImageURLAsync = useCallback(async (imgName) => {
    const covertedToURL = await fireImageURL(imgName);
    console.log("covertedToURL", covertedToURL);

    setSrcURL(covertedToURL);
  }, []);

  return [srcURL, fireImageURLAsync];
};

export default useNameToURL;
