import { IconButton, Tooltip, Typography } from "@material-ui/core";
import React from "react";

const IconTool = ({ comtitle, icon, url, imgAlt }) => {
  return (
    <Tooltip title={<Typography>{comtitle}</Typography>} placement='top'>
      <IconButton href={url} target='_blank' rel='noopener noreferrer'>
        <img src={icon} style={{ width: 125, height: 125 }} alt={imgAlt} />
      </IconButton>
    </Tooltip>
  );
};

export default IconTool;
