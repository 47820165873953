import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, TextField, Button, Typography } from "@material-ui/core";
import {
  editProject,
  fetchAllProjects,
  fireStorageUpload,
} from "../../components/firebaseFx";
import useIMG from "../../hooks/useIMG";
import AdminImg from "./AdminImg";
import AdminCrop from "./AdminCrop";
import useNameToURL from "../../hooks/useNameToURL";
// import { addNewProject } from "../../components/firebaseFx";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
  containerBox: {
    maxWidth: 360,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
});

const AdminEdit = ({
  setAddNewProject,
  setEditProjectToggle,
  adminEditData,
  setProjectData,
}) => {
  const classes = useStyles();

  const {
    title,
    content,
    keywords,
    website,
    github,
    order,
    img = "none",
    id,
  } = adminEditData;
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageAsFile, handleImageAsFile, base64] = useIMG();

  const [srcURL, fireImageURLAsync] = useNameToURL();

  const { register, handleSubmit } = useForm({});

  useEffect(() => {
    !!img && fireImageURLAsync(img);
  }, [fireImageURLAsync, img]);

  const onSubmit = async (data) => {
    // convert string number to int number
    data.order = parseInt(data.order);
    // convert word string to array
    data.keywords = data.keywords.split(",");

    // check to see if img is cropped, if not then set img to default img name
    if (!!croppedImage) {
      data.img = croppedImage.imgFile.name || null;
    } else {
      data.img = img;
    }
    await editProject(id, data);

    !!croppedImage && fireStorageUpload(croppedImage.imgFile);
    const fetchData = await fetchAllProjects();
    setProjectData(fetchData);
    setEditProjectToggle(false);
    return console.log("submitted data", data);
  };

  return (
    <div>
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box component={Paper} className={classes.containerBox}>
          <Box pt={3}>
            <Typography variant='h6'>EDIT</Typography>
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='title'
              defaultValue={title}
              name='title'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              multiline
              rowsMax={4}
              label='content'
              defaultValue={content}
              name='content'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='keywords'
              defaultValue={keywords}
              name='keywords'
              variant='outlined'
              helperText='javascript, react, redux, education, music'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              label='img'
              defaultValue={img}
              name='img'
              variant='outlined'
              disabled
            />
          </Box>

          <Box
            my={2}
            border={1}
            height={300}
            width='100%'
            style={{ position: "relative" }}
          >
            <img
              src={srcURL}
              alt='Cropped'
              style={{ objectFit: "contained", height: "100%", width: "100%" }}
            />
          </Box>

          <AdminImg
            imageAsFile={imageAsFile}
            handleImageAsFile={handleImageAsFile}
          />

          {imageAsFile && (
            <AdminCrop
              imageAsFile={imageAsFile}
              base64IMG={base64}
              croppedImage={croppedImage}
              setCroppedImage={setCroppedImage}
            />
          )}

          <Box pt={2}>
            <TextField
              size='small'
              className={classes.field}
              label='website'
              defaultValue={website}
              name='website'
              variant='outlined'
              inputRef={register()}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              label='github'
              defaultValue={github}
              name='github'
              variant='outlined'
              inputRef={register()}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='order'
              defaultValue={order}
              name='order'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box m={4}>
            <Button
              component='div'
              color='primary'
              variant='outlined'
              to='/admin/content'
              className={classes.MR}
              onClick={() => setEditProjectToggle(false)}
            >
              Cancel
            </Button>
            <Button color='secondary' variant='outlined' type='submit'>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default AdminEdit;
