import { useState } from "react";

const useIMG = () => {
  const [imageAsFile, setImageAsFile] = useState("");

  const [base64, setBase64] = useState("");

  const convertToBase64 = (imageAsFile) => {
    const image = imageAsFile;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setBase64(reader.result);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  };

  convertToBase64(imageAsFile);

  const handleImageAsFile = (e) => {
    // get file from input
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  return [imageAsFile, handleImageAsFile, base64];
};
export default useIMG;
