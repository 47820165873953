import { db, storage } from "./firebase";

export const fetchAllProjects = async () => {
  const projectList = [];
  try {
    const userQuerySnapShot = await db.collection("2020project").get();
    console.log(`%c DB:fetchAllProjects`, "color: #3373FF");

    await userQuerySnapShot.forEach((doc) => {
      projectList.push({ ...doc.data(), id: doc.id });
    });
    return projectList;
  } catch (error) {
    console.log("userQuerySnapShot for fetchAllProjects does not exist");

    console.log(`%c DB:fetchAllProjects error`, "color: 	#FF0000", error);
  }
};

export const addNewProject = async (data) => {
  console.log("data", data);

  try {
    await db.collection("2020project").add(data);

    console.log(`%c DB:addNewProject`, "color: #3373FF");
  } catch (error) {
    console.log(`%c DB:addNewProject error`, "color: 	#FF0000", error);
  }
};

export const deleteProject = (id) => {
  try {
    db.collection("2020project").doc(id).delete();
    console.log(`%c DB:deleteProject`, "color: #3373FF");
  } catch (error) {
    console.log(`%c DB:deleteProject error`, "color: 	#FF0000", error);
  }
};

export const editProject = async (id, data) => {
  try {
    await db.collection("2020project").doc(id).update(data);
    console.log(`%c DB:editProject`, "color: #3373FF");
  } catch (error) {
    console.log(`%c DB:editProject error`, "color: 	#FF0000", error);
  }
};

export const fireStorageUpload = (file) => {
  console.log("file", file);

  const uploadTask = storage.ref(`/images/${file.name}`).put(file);
  uploadTask.on("state_changed", console.log, console.error, () => {
    storage.ref("images").child(file.name);
  });
};

export const fireImageURL = async (name) => {
  let imgURL;
  await storage
    .ref(`images/${name}`)
    .getDownloadURL()
    .then(function (url) {
      imgURL = url;
      // console.log("imgURL", imgURL);
    });
  return imgURL;
};

