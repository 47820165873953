import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Menu, MenuItem } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import OutlineWrapperSp from "../../components/OutlineWrapperSp";
import { windowScrollToPosition } from "../../components/extractedFx";
import { googleLogin, logout } from "../../components/firebaseLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    paddingTop: "1.2em",
  },
}));
const Nav = ({
  setToggleModal,
  toggleModal = false,
  currentYPos,
  offsetTop = false,
  loggedIn = false,
  setToggleAdminModal,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    googleLogin();
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  const handleAdmin = () => {
    setAnchorEl(null);
    setToggleAdminModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log("offsetTop", offsetTop);
  // console.log("position", currentYPos);

  const { project, about, contact } = {
    project: -offsetTop.project + 100,
    process: -offsetTop.process + 100,
    about: -offsetTop.about + 100,
    // no need to offset contact to scroll
    contact: -offsetTop.contact,
  };

  // console.log("offsetTop", offsetTop);

  // const windowScrollToPosition = (positionName) => {
  //   window.scrollTo({
  //     top: offsetTop[positionName] - 100,
  //     behavior: "smooth",
  //   });
  // };

  // refAboutScroll();

  return (
    <div className={classes.root}>
      <AppBar color='transparent' elevation={0} position='fixed'>
        <Toolbar>
          <Box display='flex' className={classes.title}>
            <Button
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <Typography variant='h5'>Dat {loggedIn && "<<"}</Typography>
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(anchorEl)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={handleClose}
            >
              {loggedIn ? (
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              ) : (
                <MenuItem onClick={handleLogin}>Login</MenuItem>
              )}

              {loggedIn && <MenuItem onClick={handleAdmin}>Admin</MenuItem>}
            </Menu>
          </Box>

          {/* if the modal is on then do not display the desk button links
          show the menu icon
          */}
          <Box display={toggleModal ? "none" : { xs: "none", sm: "block" }}>
            <Box display='flex' mt={2}>
              <Box mx={1}>
                <OutlineWrapperSp
                  btnClick={currentYPos >= project ? true : false}
                >
                  <Button
                    variant='text'
                    style={{ backgroundColor: "white" }}
                    onClick={() => windowScrollToPosition(offsetTop, "home")}
                  >
                    <Typography variant='h6'>Home</Typography>
                  </Button>
                </OutlineWrapperSp>
              </Box>
              <Box mx={1}>
                <OutlineWrapperSp
                  btnClick={
                    project > currentYPos && currentYPos >= about ? true : false
                  }
                >
                  <Button
                    variant='text'
                    style={{ backgroundColor: "white" }}
                    onClick={() => windowScrollToPosition(offsetTop, "project")}
                  >
                    <Typography variant='h6'>Project</Typography>
                  </Button>
                </OutlineWrapperSp>
              </Box>
              {/* <Box mx={1}>
                <OutlineWrapperSp
                  btnClick={
                    process > currentYPos && currentYPos >= about ? true : false
                  }
                >
                  <Button
                    variant='text'
                    style={{ backgroundColor: "white" }}
                    onClick={() => windowScrollToPosition(offsetTop, "process")}
                  >
                    <Typography variant='h6'>Process</Typography>
                  </Button>
                </OutlineWrapperSp>
              </Box> */}
              <Box mx={1}>
                <OutlineWrapperSp
                  btnClick={
                    about > currentYPos && currentYPos > contact ? true : false
                  }
                >
                  <Button
                    variant='text'
                    style={{ backgroundColor: "white" }}
                    onClick={() => windowScrollToPosition(offsetTop, "about")}
                  >
                    <Typography variant='h6'>About</Typography>
                  </Button>
                </OutlineWrapperSp>
              </Box>
              <Box mx={1}>
                <OutlineWrapperSp
                  btnClick={contact >= currentYPos ? true : false}
                >
                  <Button
                    variant='text'
                    style={{ backgroundColor: "white" }}
                    onClick={() => windowScrollToPosition(offsetTop, "contact")}
                  >
                    <Typography variant='h6'>Contact</Typography>
                  </Button>
                </OutlineWrapperSp>
              </Box>
            </Box>
          </Box>
          {/* display the x icon when togglemodal is on all screen size */}
          <Box display={toggleModal ? "" : { xs: "block", sm: "none" }}>
            <IconButton
              edge='start'
              data-testid='clearIcon'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => setToggleModal(!toggleModal)}
            >
              {toggleModal ? (
                <ClearIcon fontSize='large' />
              ) : (
                <MenuIcon data-testid='menuIcon' fontSize='large' />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Nav;
