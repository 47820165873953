import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Media from "./Media";
import HingeAnimate from "../../components/HingeAnimate";
import NavTopSpacer from "../../components/NavTopSpacer";
import { fetchAllProjects } from "../../components/firebaseFx";
import { nestedSort } from "../../components/extractedFx";

const Project = () => {
  const [projectData, setProjectData] = useState([]);
  // sort array of object by nested key
  projectData.sort(nestedSort("order", null, "asc"));

  const asyncFetchAllProjects = async () => {
    const data = await fetchAllProjects();
    setProjectData(data);
  };


  useEffect(() => {
    asyncFetchAllProjects();
  }, []);

  return (
    <>
      <NavTopSpacer />
      <Box>
        <Box textAlign='center'>
          <Typography variant='h2'>PROJECT</Typography>
        </Box>

        <Box my={5}>
          <Grid
            container
            justify='space-around'
            alignItems='center'
            direction='row'
          >
            {projectData.length > 0 &&
              projectData.map((item, index) => {
                return (
                  <Grid item sm={12} md={12} key={index}>
                    <Box my={4}>
                      <HingeAnimate>
                        <Media
                          title={item.title}
                          content={item.content}
                          keywords={item.keywords}
                          github={item.github}
                          website={item.website}
                          img={item.img}
                        />
                      </HingeAnimate>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Box>
      {/* <Box>
        <MediaCard imgURL={mtnIMG} title={"One IPC"} />

        <Box display='flex' flexDirection='column' py={2} border={1}></Box>
      </Box> */}
    </>
  );
};

export default Project;
