import { Box, Button } from "@material-ui/core";

const AdminImg = ({ imageAsFile, handleImageAsFile }) => {
  console.log("imageAsFile", imageAsFile);

  return (
    <Box my={2}>
      <Box
        p={2}
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <Box>
          <Button variant='contained' component='label'>
            Upload File
            <input type='file' hidden onChange={handleImageAsFile} />
          </Button>
        </Box>
        {/* <Box p={3}>{imageAsFile.name}</Box> */}
      </Box>
    </Box>
  );
};

export default AdminImg;
