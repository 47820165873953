import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { windowScrollToPosition } from "../../components/extractedFx";
import OutlineWrapperSp from "../../components/OutlineWrapperSp";
const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 900,
    letterSpacing: 3,
    color: "black",
    "&:hover": {},
  },
}));
const LinkMobile = ({ setToggleModal, offsetTop = false }) => {
  const classes = useStyles();

  const handleBtnClick = (positionName) => {
    setToggleModal(false);
    windowScrollToPosition(offsetTop, positionName);
  };

  return (
    <Box
      textAlign='left'
      display='flex'
      justifyContent='center'
      alignItems='left'
      flexDirection='column'
      width='100%'
      height='100%'
      overflow='hidden'
      px={3}
    >
      <Box p={2}>
        <OutlineWrapperSp>
          <Button
            onClick={() => handleBtnClick("home")}
            variant='contained'
            style={{ backgroundColor: "white" }}
          >
            <Typography variant='h3' className={classes.button}>
              Home
            </Typography>
          </Button>
        </OutlineWrapperSp>
      </Box>
      <Box p={2}>
        <OutlineWrapperSp>
          <Button
            onClick={() => handleBtnClick("project")}
            variant='contained'
            style={{ backgroundColor: "white" }}
          >
            <Typography variant='h3' className={classes.button}>
              Project
            </Typography>
          </Button>
        </OutlineWrapperSp>
      </Box>
      <Box p={2}>
        <OutlineWrapperSp>
          <Button
            onClick={() => handleBtnClick("process")}
            variant='contained'
            style={{ backgroundColor: "white" }}
          >
            <Typography variant='h3' className={classes.button}>
              Process
            </Typography>
          </Button>
        </OutlineWrapperSp>
      </Box>
      <Box p={2}>
        <OutlineWrapperSp>
          <Button
            onClick={() => handleBtnClick("about")}
            variant='contained'
            style={{ backgroundColor: "white" }}
          >
            <Typography variant='h3' className={classes.button}>
              ABOUT
            </Typography>
          </Button>
        </OutlineWrapperSp>
      </Box>
      <Box p={2}>
        <OutlineWrapperSp>
          <Button
            onClick={() => handleBtnClick("contact")}
            variant='contained'
            style={{ backgroundColor: "white" }}
          >
            <Typography variant='h3' className={classes.button}>
              CONTACT
            </Typography>
          </Button>
        </OutlineWrapperSp>
      </Box>
    </Box>
  );
};

export default LinkMobile;
