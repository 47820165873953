import { Box, Button, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";
import ClearIcon from "@material-ui/icons/Clear";
import AdminMedia from "./AdminMedia";
import { fetchAllProjects } from "../../components/firebaseFx";
import AdminAddNew from "./AdminAddNew";
import AdminEdit from "./AdminEdit";
import { nestedSort } from "../../components/extractedFx";

const AdminModal = ({ setToggleAdminModal }) => {
  const [projectData, setProjectData] = useState([]);
  const [addNewProject, setAddNewProject] = useState(false);
  const [editProjectToggle, setEditProjectToggle] = useState(false);
  const [adminEditData, setAdminEditData] = useState(null);
  const modalAppear = useSpring({
    config: config.default,
    from: {
      display: "block",
      position: "fixed",
      zIndex: 1500,

      width: "100%",
      height: "100%",
      backgroundColor: "#f5f5f5",
      color: "black",
      overflowY: "scroll",
      overflowX: "hidden",
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  const asyncFetchAllProjects = async () => {
    const data = await fetchAllProjects();
    setProjectData(data);
  };

  useEffect(() => {
    asyncFetchAllProjects();
  }, []);

  // sort projectData
  projectData.sort(nestedSort("order", null, "asc"));

  return (
    <animated.div style={modalAppear}>
      {/* modal only appears when in mobile page size view, otherwise desk link appears */}

      <Box style={{ maxWidth: 900 }} mx='auto'>
        <Box mr='auto'>
          <Box textAlign='right'>
            <IconButton onClick={() => setToggleAdminModal(false)}>
              <ClearIcon fontSize='large' />
            </IconButton>
          </Box>
          <Box>
            <Button
              variant='contained'
              onClick={() => setAddNewProject(!addNewProject)}
            >
              {addNewProject && "close"} Add New Project
            </Button>
          </Box>

          {addNewProject && (
            <AdminAddNew
              setAddNewProject={setAddNewProject}
              setProjectData={setProjectData}
            />
          )}

          {editProjectToggle && (
            <AdminEdit
              setEditProjectToggle={setEditProjectToggle}
              adminEditData={adminEditData}
              key={adminEditData.title}
              setProjectData={setProjectData}
            />
          )}
          {projectData.map((item) => (
            <AdminMedia
              key={item.title}
              mediaData={item}
              setToggleAdminModal={setToggleAdminModal}
              setAdminEditData={setAdminEditData}
              setEditProjectToggle={setEditProjectToggle}
            />
          ))}
        </Box>
      </Box>
    </animated.div>
  );
};

export default AdminModal;
