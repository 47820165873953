import React, { useLayoutEffect, useRef, useState } from "react";
import { useSpring, animated, config } from "react-spring";

const OutlineWrapperSp = ({
  bgColorTop = "lightblue",
  bgColorBtm = "lightgreen",
  children,
  TopYTopOffset = -5,
  TopXOffset = -5,
  BtmYTopOffset = 5,
  BtmXLeftOffset = 5,
  btnClick = false,
  borderRadius = "10%",
}) => {
  const [handleBtnAnimateToggle, setHandleBtnAnimateToggle] = useState(false);

  const [heightCal, setHeightCal] = useState(0);
  const [widthCal, setWidthCal] = useState(0);

  const ref = useRef(null);
  // console.log("offsetTop", offsetTop);

  useLayoutEffect(() => {
    setHeightCal(ref.current.getBoundingClientRect().height);

    const delayWidthCall = setTimeout(() => {
      setWidthCal(ref.current.getBoundingClientRect().width);
    }, 100);

    return () => {
      clearInterval(delayWidthCall);
    };
  }, [heightCal, widthCal]);

  const onBtnAnimateTop = useSpring({
    // position: "absolute",
    backgroundColor: bgColorTop,
    height: heightCal,
    width: widthCal,
    borderRadius: handleBtnAnimateToggle ? "10%" : borderRadius,
    transform:
      handleBtnAnimateToggle || btnClick
        ? `translate3d(0px, 0px, 0px)`
        : `translate3d(${TopXOffset}px, ${TopYTopOffset}px, 0px)`,
    config: config.default,
  });

  const onBtnAnimateBtm = useSpring({
    // position: "absolute",
    backgroundColor: bgColorBtm,
    height: heightCal,
    width: widthCal,
    borderRadius: handleBtnAnimateToggle ? borderRadius : "10%",
    transform:
      handleBtnAnimateToggle || btnClick
        ? `translate3d(${BtmXLeftOffset}px, ${BtmYTopOffset}px, 0px)`
        : `translate3d(0px, 0px, 0px)`,

    config: config.default,
  });

  return (
    <>
      <div
        onMouseOver={() => setHandleBtnAnimateToggle(true)}
        onMouseLeave={() => setHandleBtnAnimateToggle(false)}
        style={{ display: "inline-flex" }}
      >
        <div ref={ref} style={{ display: "inline-flex", zIndex: 1000 }}>
          {children}
        </div>
        <div style={{ position: "absolute" }}>
          <animated.div style={onBtnAnimateTop}></animated.div>
        </div>
        <div style={{ position: "absolute" }}>
          <animated.div style={onBtnAnimateBtm}></animated.div>
        </div>
      </div>
    </>
  );
};

export default OutlineWrapperSp;
