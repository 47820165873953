import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, TextField, Button, Typography } from "@material-ui/core";
import {
  addNewProject,
  fetchAllProjects,
  fireStorageUpload,
} from "../../components/firebaseFx";
import AdminImg from "./AdminImg";
import useIMG from "../../hooks/useIMG";
import AdminCrop from "./AdminCrop";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { stringDate } from "../../../components/others/Time";
// import { addNewUnit } from "./redux/adminAction";
// import TitleHeader from "../../../components/titleHeader/TitleHeader";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
  containerBox: {
    maxWidth: 360,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
});

const AdminAddNew = ({ setAddNewProject, setProjectData }) => {
  const classes = useStyles();
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageAsFile, handleImageAsFile, base64] = useIMG();
  const { register, handleSubmit } = useForm({});




  const onSubmit = async (data) => {
    data.order = parseInt(data.order);
    data.keywords = data.keywords.split(",");
    data.img = croppedImage.imgFile.name || null;
    await addNewProject(data);

    console.log("croppedImage", croppedImage);

    !!croppedImage && fireStorageUpload(croppedImage.imgFile);
    const fetchData = await fetchAllProjects();
    setProjectData(fetchData);
    setAddNewProject(false);

    return console.log("submitted data", data);
  };

  return (
    <div>
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box component={Paper} className={classes.containerBox}>
          <Box pt={3}>
            <Typography variant='h6'>ADD NEW</Typography>
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='title'
              name='title'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='content'
              name='content'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='keywords'
              name='keywords'
              variant='outlined'
              helperText='javascript, react, redux, education, music'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              label='website'
              name='website'
              variant='outlined'
              inputRef={register()}
            />
          </Box>

          <AdminImg
            imageAsFile={imageAsFile}
            handleImageAsFile={handleImageAsFile}
          />

          {imageAsFile && (
            <AdminCrop
              imageAsFile={imageAsFile}
              base64IMG={base64}
              croppedImage={croppedImage}
              setCroppedImage={setCroppedImage}
            />
          )}

          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              label='github'
              name='github'
              variant='outlined'
              inputRef={register()}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              label='order'
              name='order'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box m={4}>
            <Button
              component='div'
              color='primary'
              variant='outlined'
              to='/admin/content'
              className={classes.MR}
              onClick={() => setAddNewProject(false)}
            >
              Cancel
            </Button>
            <Button color='secondary' variant='outlined' type='submit'>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default AdminAddNew;
