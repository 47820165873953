import { Box } from "@material-ui/core";
import React from "react";
import { useSpring, animated, config } from "react-spring";
import LinkMobile from "./LinkMobile";
import Nav from "./Nav";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   title: {
//     flexGrow: 1,
//   },
//   cover: {
//     display: "block" /* Hidden by default */,
//     position: "fixed" /* Stay in place */,
//     zIndex: 1 /* Sit on top */,
//     left: 0,
//     top: 0,
//     // width: "100%" /* Full width */,
//     // height: "100%" /* Full height */,
//     // backgroundColor: "rgb(0, 0, 0)" /* Fallback color */,
//     // backgroundColor: "rgba(0, 0, 0, 0.6)" /* Black w/ opacity */,
//     color: "white",
//     overflow: "hidden",
//   },
//   button: {
//     fontWeight: 900,
//     letterSpacing: 3,
//     color: "black",
//     "&:hover": {},
//   },
// }));

const MenuModal = ({ setToggleModal, toggleModal, offsetTop }) => {
  // const classes = useStyles();
  const modalAppear = useSpring({
    config: config.default,
    from: {
      display: "block",
      position: "fixed",
      zIndex: 1500,
      right: 0,
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#f5f5f5",
      color: "black",
      overflow: "hidden",
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <div>
      <div></div>
      <animated.div style={modalAppear}>
        {/* modal only appears when in mobile page size view, otherwise desk link appears */}
        <Nav setToggleModal={setToggleModal} toggleModal={toggleModal} />

        <Box width='100%' height='100%'>
          <LinkMobile setToggleModal={setToggleModal} offsetTop={offsetTop} />
        </Box>
        {/* <Box display={{ sm: "none", md: "block" }} width='100%' height='100%'>
          <LinkDesk setToggleModal={setToggleModal} />
        </Box> */}
      </animated.div>
    </div>
  );
};

export default MenuModal;
