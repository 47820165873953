import {
  Box,
  Button,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, {useEffect } from "react";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import GitHubIcon from "@material-ui/icons/GitHub";
import PublicIcon from "@material-ui/icons/Public";
import useNameToURL from "../../hooks/useNameToURL";

const Media = ({
  title = "title",
  content = "content",
  keywords = ["one", "two", "three", "four"],
  github = false,
  website = false,
  img,
}) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [srcURL, fireImageURLAsync] = useNameToURL();


  useEffect(() => {
    !!img && fireImageURLAsync(img);
  }, [fireImageURLAsync, img]);

  return (
    <Grid container direction='row' justify='center' alignItems='flex-start'>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            maxWidth: 300,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <img
            alt='mtn'
            src={`${srcURL}`}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "2%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          px={2}
          style={
            smUp
              ? { maxWidth: 500, marginLeft: "auto", marginRight: "auto" }
              : { maxWidth: 300, marginLeft: "auto", marginRight: "auto" }
          }
        >
          <Typography variant='h6'>{title}</Typography>
          <Typography>{content}</Typography>
          <Box my={1} display='flex'>
            {website && (
              <Box pr={1}>
                <Tooltip
                  title={<Typography>Website</Typography>}
                  placement='top'
                >
                  <Button
                    size='small'
                    variant='outlined'
                    // style={{ backgroundColor: "white" }}
                    href={website}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <PublicIcon />
                  </Button>
                </Tooltip>
              </Box>
            )}
            {github && (
              <Box pr={1}>
                <Tooltip
                  title={<Typography>GitHub</Typography>}
                  placement='top'
                >
                  <Button
                    size='small'
                    variant='outlined'
                    // style={{ backgroundColor: "white" }}
                    rel='noopener noreferrer'
                    target='_blank'
                    href={github}
                  >
                    <GitHubIcon />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
          <Box pt={1}>
            <Typography component='div' variant='body1'>
              {keywords.map((item, index) => {
                return (
                  <Chip
                    variant='outlined'
                    size='small'
                    label={item}
                    style={{ marginRight: 5, marginTop: 2 }}
                    key={item + index}
                  />
                );
              })}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Media;
