import { auth, firebase } from "./firebase";

export const googleLogin = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  try {
    await firebase.auth().signInWithPopup(provider);
    console.log(`%c DB:googleLogin successful`, "color: #3373FF");
  } catch (error) {
    console.log(`%c DB:googleLogin error`, error, "color: #3373FF");
  }
};

export const verifyAuth = async (setLoggedIn) => {
  firebase.auth().onAuthStateChanged((user) => {
    console.log(`%c DB:verifyAuth called`, "color: #3373FF");

    if (user) {
      console.log(
        `%c DB:verifyAuth detected login user`,
        "color: #3373FF",
        user
      );
      setLoggedIn(true);
    } else {
      console.log(`%c DB:verifyAuth detect no login user`, "color: #3373FF");
      setLoggedIn(false);
    }
  });
};

export const logout = () => {
  try {
    auth.signOut();
    console.log(`%c DB:logout successful`, "color: #3373FF");
  } catch (error) {
    console.log(`%c DB:logout error`, error, "color: #3373FF");
  }
};
