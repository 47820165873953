import { Box, Button, Slider, TextField } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../components/cropImage";

const AdminCrop = ({
  base64IMG,
  croppedImage,
  setCroppedImage,
  imageAsFile,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [name, setName] = useState(imageAsFile.name || "");

  // saves the croppedAreaPixels after manipulatng the image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // takes the croppedAreaPixels and crops the image with canvas
  // returns a blob url for local viewing and a blob data for server storage
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        base64IMG,
        croppedAreaPixels,
        rotation
      );
      // adding a name to the cropped Image imgFile before sending it over to be submitted and uploaded to firestore
      croppedImage.imgFile.name = name;
      console.log("croppedImage", croppedImage);

      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, base64IMG, setCroppedImage, name]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, [setCroppedImage]);

  console.log("name", name);

  return (
    <>
      <Box
        height={300}
        width='100%'
        border={1}
        style={{ position: "relative" }}
        zIndex='tooltip'
      >
        <Cropper
          image={base64IMG}
          crop={crop}
          // rotation={rotation}
          zoom={zoom}
          aspect={3 / 2}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <Box p={2}>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby='Zoom'
          onChange={(e, zoom) => setZoom(zoom)}
        />
      </Box>
      <Box>
        <TextField
          size='small'
          // className={classes.field}
          label='image name'
          name='image name'
          value={name}
          variant='outlined'
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box m={3}>
        <Button onClick={showCroppedImage} variant='contained'>
          Crop & Show
        </Button>

        <Button onClick={onClose} variant='contained' style={{ marginLeft: 5 }}>
          Close
        </Button>
      </Box>
      {croppedImage && (
        <Box height={300} width='100%' style={{ position: "relative" }}>
          <img
            src={croppedImage.url}
            alt='Cropped'
            style={{ objectFit: "contained", height: "100%", width: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

export default AdminCrop;
