import { Box, Button, Paper } from "@material-ui/core";
import React from "react";
import { deleteProject } from "../../components/firebaseFx";

const AdminMedia = ({
  setEditProjectToggle,
  setToggleAdminModal,
  setAdminEditData,
  mediaData,
}) => {
  const {
    title,
    content,
    keywords,
    website,
    github,
    order,
    id,
    img,
  } = mediaData;
  const stringKeywords = keywords.join(", ");

  const handleDelete = (id) => {
    setToggleAdminModal(false);
    deleteProject(id);
  };

  const handleEdit = () => {
    // setAdminEditData();
    // setEditProjectToggle(false);

    setAdminEditData(() => mediaData);
    setEditProjectToggle(true);
  };

  return (
    <Box mt={3}>
      <Paper>
        <Box p={2}>
          <Box display='flex'>
            <Box width={100}>Title</Box>
            <Box flexGrow={1}>{title}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Content</Box>
            <Box flexGrow={1}>{content}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Keywords</Box>
            <Box flexGrow={1}>{stringKeywords}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Img</Box>
            <Box flexGrow={1}>{img}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Website</Box>
            <Box flexGrow={1}>{website}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Github</Box>
            <Box flexGrow={1}>{github}</Box>
          </Box>
          <Box display='flex'>
            <Box width={100}>Order</Box>
            <Box flexGrow={1}>{order}</Box>
          </Box>
          <Box mt={2}>
            <Button onClick={handleEdit}>Edit</Button>
            <Button
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(id);
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdminMedia;
