import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import ReactVisibilitySensor from "react-visibility-sensor";

const HingeAnimate = ({ children }) => {
  const [hinge, setHinge] = useState(false);

  const hingeAnimateComp = useSpring({
    transform: hinge
      ? `translate3d(0px, 0px, 0px)`
      : `translate3d(-50px, 50px, 0px)`,
    // transform: hinge ? "rotate(0deg)" : "rotate(10deg)",
    // transformOrigin: "top left",
    opacity: hinge ? 1 : 0,
    config: config.default,
  });

  function onChange(isVisible) {
    isVisible && setHinge(true);
  }

  return (
    <div>
      <ReactVisibilitySensor
        onChange={onChange}
        minTopValue={50}
        partialVisibility
      >
        <animated.div style={hingeAnimateComp}>{children}</animated.div>
      </ReactVisibilitySensor>
    </div>
  );
};

export default HingeAnimate;
