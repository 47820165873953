import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSpring, animated, config } from "react-spring";

import NavTopSpacer from "../../components/NavTopSpacer";

const Home = () => {
  // const [items, set] = useState(true);
  // const [toggle, setToggle] = useState(true);

  // const transition = useTransition(toggle, null, {
  //   from: { opacity: 1, color: "blue", fontSize: "0em" },
  //   enter: { opacity: 1, color: "red", fontSize: "3em" },
  //   leave: { opacity: 0, color: "orange", fontSize: "0em" },
  //   config: config.gentle,
  // });

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    config: config.molasses,
  });

  return (
    <>
      <NavTopSpacer />
      <Box style={{ height: "100%" }}>
        <Grid container direction='column' justify='center' alignItems='center'>
          {/* <Grid item>
            <Box
              display='flex'
              justifyContent='space-around'
              alignItems='center'
              flexDirection='column'
              py={9}
            >
              <Button variant='contained' onClick={() => setToggle(!toggle)}>
                Toggle on Off
              </Button>
              {transition.map(({ item, key, props }) => (
                <Typography>
                  <animated.div key={key} style={props}>
                    {item && "Welcome"}
                  </animated.div>
                </Typography>
              ))}
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={9}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              textAlign='left'
              style={{ height: "75vh" }}
              px={2}
            >
              <animated.div style={fade}>
                <Typography variant='h4'>
                  Working to improve the health and wellbeing of everyone
                  through technology, health education, & public health, one
                  project at a time.
                </Typography>
              </animated.div>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={9}>
            <Typography variant='h5'>PUBLIC HEALTH</Typography>
            <Typography variant='h5'>WEB DEVELOPMENT</Typography>
            <Typography variant='h5'>INFECTION CONTROL & PREVENTION</Typography>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Home;
