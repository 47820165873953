import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import LanguageIcon from "@material-ui/icons/Language";
import ReactIcon from "./../../image/react.png";
import ReduxIcon from "./../../image/redux.png";
import FirebaseIcon from "./../../image/firebase.png";
import JSIcon from "./../../image/javascript.png";
import NavTopSpacer from "../../components/NavTopSpacer";
import HingeAnimate from "../../components/HingeAnimate";
import IconTool from "./IconTool";
const About = () => {
  return (
    <>
      <NavTopSpacer />
      <Box
        textAlign='center'
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'
        flexDirection='column'
      >
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'
        >
          <Grid item>
            <HingeAnimate>
              <Box style={{ maxWidth: 300, minHeight: 300 }} p={2}>
                <Box>
                  <LocalHospitalIcon
                    style={{ fontSize: "7em", color: "red" }}
                  />
                </Box>
                <Box textAlign='left'>
                  <Box textAlign='center'>
                    <Typography variant='h4'>HEALTHCARE</Typography>
                  </Box>
                  <Typography variant='body1'>
                    Experience healthcare professional with dual degree in
                    medicine and public health. A strong promoter of healthcare
                    education and infection control & prevention education.
                  </Typography>
                </Box>
              </Box>
            </HingeAnimate>
          </Grid>
          <Grid item>
            <HingeAnimate>
              <Box style={{ maxWidth: 300, minHeight: 300 }} p={2}>
                <Box>
                  <LanguageIcon style={{ fontSize: "7em", color: "blue" }} />
                </Box>
                <Box textAlign='left'>
                  <Box textAlign='center'>
                    <Typography variant='h4'>FULL STACK</Typography>
                  </Box>
                  <Typography variant='body1'>
                    Fullstack web developer with extensive experience in creating
                    dynamic scalable website. Dynamic and responsive frontend is
                    create with ReactJS. Backend is built with highly secure and
                    advance Firebase web development platform.
                  </Typography>
                </Box>
              </Box>
            </HingeAnimate>
          </Grid>
        </Grid>

        <Box my={14} style={{ width: "100%" }}>
          <Grid
            container
            direction='row'
            justify='space-evenly'
            alignItems='center'
          >
            <Grid item xs={6} sm={3}>
              <HingeAnimate>
                <IconTool
                  comtitle='Redux'
                  icon={ReduxIcon}
                  url='https://redux.js.org/'
                  imgAlt='redux'
                />
              </HingeAnimate>
            </Grid>
            <Grid item xs={6} sm={3}>
              <HingeAnimate>
                <IconTool
                  comtitle='React'
                  icon={ReactIcon}
                  url='https://reactjs.org/'
                  imgAlt='react'
                />
              </HingeAnimate>
            </Grid>
            <Grid item xs={6} sm={3}>
              <HingeAnimate>
                <IconTool
                  comtitle='Javascript'
                  icon={JSIcon}
                  url='https://developer.mozilla.org/en-US/docs/Web/JavaScript'
                  imgAlt='javascript'
                />
              </HingeAnimate>
            </Grid>
            <Grid item xs={6} sm={3}>
              <HingeAnimate>
                <IconTool
                  comtitle='Firebase'
                  icon={FirebaseIcon}
                  url='https://firebase.google.com/'
                  imgAlt='firebase'
                />
              </HingeAnimate>
            </Grid>
          </Grid>
          <Box pt={3}>
            <Typography variant='h4'>TECH STACK</Typography>
          </Box>
        </Box>

        {/* <Box>
          <Box p={3}>
            <Typography variant='h4'>SERVICES & SKILLSET</Typography>
          </Box>
          <Services />
        </Box> */}
        {/* <Box>
          <IconButton>
            <GitHubIcon style={{ fontSize: "6em", color: "#6F42C1" }} />
          </IconButton>
        </Box> */}
      </Box>
    </>
  );
};

export default About;
